<template>
    <table id="executives-listing" class="table table-striped dataTable no-footer" role="grid">
        <thead>
            <tr role="row">
                <td>Code</td>
                <td>Name</td>
                <td>Constituency</td>
                <td>Branches</td>
                <td>Total Voters</td>
                <td>Created At</td>
                <td>Updated At</td>
            </tr>
        </thead>

        <tbody>
            <tr role="row" v-for="(area, index) in electoralAreas" :key="index">
                <td>{{ area.code }}</td>
                <td>{{ area.name }}</td>
                <td>{{ area.constituency ? area.constituency.name : 'N/A' }}</td>
                <td>{{ area.branches.length }}</td>
                <td>{{ numberOfVoters(area.branches) }}</td>
                <td>{{ area.created_at | formattedDateTime }}</td>
                <td>{{ area.updated_at | formattedDateTime }}</td>
                <td>
                    <button class="btn btn-warning btn-action mr-3" @click="$emit('edit', area)" data-toggle="popover" data-content="Edit Electoral Area">
                        <i class="mdi mdi-pencil"></i>
                    </button>

                    <button class="btn btn-danger btn-action" @click="$emit('delete', area.id)" data-toggle="popover" data-content="Delete Electoral Area">
                        <i class="mdi mdi-trash-can"></i>
                    </button>
                </td>
            </tr>
        </tbody>
    </table>
</template>

<script>
    export default {
        props: ['electoralAreas'],

        methods: {
            numberOfVoters(branches) {
                return branches.reduce((count, branch) => count + branch.registered_voters, 0);
            }
        }
    }
</script>