<template>
    <modal
        :force="true"
        :show="show"
        :title="title"
        :ok-text="title"
        :close-when-cancel="true"
        @cancel="$emit('update:show', false)"
        @ok="saveElectoralArea"
        @update:show="$emit('update:show', $event)">
			<div class="row justify-content-center">
				<div class="col-xs-12 col-10">
                    <div class="form-group" :class="getFieldClass('name')">
                        <label for="name">Code</label>
                        <input
                            id="code"
                            type="text"
                            name="code"
                            v-model="payload.code"
                            class="form-control"
                            v-validate="rules.code"
                            placeholder="EA Code">

                        <error-label :message="errors.first('code')" />
                    </div>

                    <div class="form-group" :class="getFieldClass('name')">
                        <label for="name">Name</label>
                        <input
                            id="name"
                            type="text"
                            name="name"
                            v-model="payload.name"
                            class="form-control"
                            v-validate="rules.name"
                            placeholder="Name">

                        <error-label :message="errors.first('name')" />
                    </div>

                    <div class="form-group" :class="getFieldClass('constituency_id')">
                        <label>Constituency</label>

                        <div class="select2-wrapper">
                            <select class="select-2 form-control"
                                name="constituency_id"
                                ref="constituency"
                                v-validate="rules.constituency_id">
                                <option value="">Select a Constituency</option>
                                <option v-for="(constituency, index) in constituencies"
                                    :value="constituency.id"
                                    :key="index">{{ constituency.name }}</option>
                            </select>
                        </div>

                        <error-label :message="errors.first('constituency_id')" />
                    </div>
				</div>
			</div>
    </modal>
</template>

<script>
import {mapGetters, mapActions} from 'vuex';

export default {
    props: {
        show: {
            type: Boolean,
            default: false
        },

        electoralArea: {
            type: Object,
            default: {}
        }
    },

    data() {
        return {
            payload: {
                code: '',
                name: '',
                constituency_id: ''
            },

            rules: {
                code: 'required|min:2',
                name: 'required|min:2',
                constituency_id: 'required'
            }
        };
    },

    computed: {
        ...mapGetters({
            constituencies: 'Constituencies/getAll'
        }),

        /**
         * Check if the current operation is an update.
         *
         * @return {Boolean}
         */
        isUpdate() {
            return Object.keys(this.electoralArea).length > 0;
        },

        /**
         * Title for the modal depending on the current operation.
         *
         * @return {String}
         */
        title() {
            return this.isUpdate ? 'Edit Electoral Area' : 'Add Electoral Area';
        }
    },

    watch: {
        /**
         * Toggle the id property depending on the save operation. Update or Save.
         *
         * @param {Boolean} value
         * @return {Undefined}
         */
        show(value) {
            this.resetModal();

            if (this.isUpdate) {
                this.getElectoralAreaDetails();
                this.$set(this.payload, 'id', this.electoralArea.id);

                this.$nextTick(() => {
                    $(this.$refs.constituency).val(this.electoralArea.constituency_id).change();
                });
            } else {
                this.$delete(this.payload, 'id');
            }
        }
    },

    methods: {
        /**
         * Reset the modal.
         *
         * @return {Undefined}
         */
        resetModal() {
            this.code = '';
            this.name = '';

            this.$nextTick(() => this.errors.clear());
        },

        /**
         * Compute the has danger class for the given field.
         *
         * @return {Object}
         */
        getFieldClass(field) {
            return { 'has-danger': this.errors.has(field) };
        },

        /**
         * Handle the change event when a new constituency is selected
         *
         * @param {Object} event Change event
         */
        constituencyChanged(event) {
            this.payload.constituency_id = event.target.value;
        },

        /**
         * Register event listeners to handle select 2
         */
        registerEventListeners() {
            $(this.$refs.constituency).on('change', this.constituencyChanged);
        },

        /**
         * Save the edited electoral-area
         */
        saveElectoralArea()
        {
            this.$validator.validateAll()
                .then((response) => {
                    if (response) {
                        this.$emit('save-electoral-area', this.payload);
                    } else {
                        let messageConf = this.buildErrors(this.$validator.errors);

                        this.notify(messageConf, 'error');
                    }
                });
        },

        /**
         * Fill payload with electoral-area's data
         */
        getElectoralAreaDetails() {
            this.payload = {
                ...this.electoralArea
            };
        }
    },

    mounted() {
        this.registerEventListeners();
    }
}
</script>