<template>
    <div class="card">
        <div class="card-body">
            <h1 class="card-title">
                <i class="mdi mdi-umbrella"></i>
                Electoral Areas
            </h1>

            <p class="card-description">List of electoral areas</p>

            <div class="row">
                <div class="col-12">
                    <div id="electoral-area-listing-wrapper" class="dataTables_wrapper container-fluid dt-bootstrap4 no-footer">
                        <div class="row">
                            <div class="col-xs-12 col-4">
                                <button type="button" class="btn btn-primary" @click="addElectoralArea">
                                    <i class="mdi mdi-plus"></i>
                                    Add Electoral Area
                                </button>
                            </div>

                            <div class="col-xs-12 col-8">
                                <toolbar
                                    @show="val => params.per_page = val"
                                    @search="val => params.keyword = val" />
                            </div>
                        </div>

                        <div class="row">
                            <div class="col-12">
                                <template v-if="electoral_areas.length">
                                    <div class="table-responsive">
                                        <electoral-areas-table
                                            @edit="editElectoralArea"
                                            :electoral-areas="electoral_areas"
                                            @delete="deleteElectoralArea" />
                                    </div>

                                    <app-pagination
                                        v-if="pageDetails && pageDetails.total"
                                        :pageDetails="pageDetails"
                                        @navigate="getElectoralAreas($event)" />
                                </template>

                                <template v-else>
                                    <p class="lead mt-5 mb-5 text-center">There are no electoral areas in the system.</p>
                                </template>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <modal
            :show="showModal"
            :electoral-area="modalElectoralArea"
            @save-electoral-area="saveElectoralArea"
            @update:show="val => showModal = val"></modal>
    </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';

import Modal from './Modal';
import ElectoralAreasTable from './Table';

export default {
    components: {
        Modal,
        ElectoralAreasTable
    },

    computed: {
        ...mapGetters({
            electoral_areas: 'ElectoralAreas/getAll',
            pageDetails: 'ElectoralAreas/getPageDetails'
        })
    },

    data() {
        return {
            showModal: false,
            modalElectoralArea: {},
            params: {
                paginate: true,
                per_page: null,
                keyword: null
            }
        }
    },

    watch: {
        params: {
            deep: true,
            handler() {
                this.getElectoralAreas();
            }
        }
    },

    methods: {
        ...mapActions({
            store: 'ElectoralAreas/store',
            load: 'ElectoralAreas/all',
            update: 'ElectoralAreas/update',
            delete: 'ElectoralAreas/delete',
            loadConstituencies: 'Constituencies/all'
        }),

        /**
         * Add a ElectoralArea
         *
         * @return {Undefined}
         */
        addElectoralArea() {
            this.modalElectoralArea = {};
            this.showModal = true;
        },

        /**
         * Add a new electoral area
         *
         * @param {Object} electoralArea ElectoralArea to be added
         * @return {Undefined}
         */
        saveElectoralArea(electoralArea) {
            let keyword = 'added';
            let savingMethod = this.store;
            let payload = { data: electoralArea };

            if (electoralArea.id !== 'undefined' && electoralArea.id) {
                keyword = 'updated';
                payload.id = electoralArea.id;
                savingMethod = this.update;
            }

            savingMethod(payload)
                .then(response => {
                    this.notify(`The electoral area was ${keyword} successfully.`);
                    this.getElectoralAreas();
                    this.showModal = false;
                    this.modalElectoralArea = {};
                })
                .catch(errors => this.notify(this.buildErrors(errors), 'error'));
        },

        /**
         * Set up a ElectoralArea and show edit modal for editing it
         *
         * @param {Object} ElectoralArea ElectoralArea to be updated
         * @return {Undefined}
         */
        editElectoralArea(electoralArea) {
            this.showModal = true;
            this.modalElectoralArea = { ...electoralArea };
        },

        /**
         * Confirm the delete and handle the delete action of a electoral area
         *
         * @param {Number} id Id of electoral area to be deleted
         * @return {Undefined}
         */
        deleteElectoralArea(id) {
            Swal({
                title: "Are you sure?",
                text: "Once deleted, you will not be able to recover this electoral area and all associated data!",
                type: 'warning',
                showCancelButton: true,
                confirmButtonText: 'Yes - Delete it.',
                confirmButtonClass: 'bg-danger',
                cancelButtonText: 'No - Cancel Delete'
            })
            .then(response => {
                if (response.value) {
                    this.delete(id)
                        .then(() => {
                            this.notify('The electoral area was deleted successfully.');
                            this.getElectoralAreas();
                        })
                        .catch(errors => this.notify(this.buildErrors(errors), 'error'))
                }
            });
        },

        /**
         * Load constituencies
         *
         * @param {Object} query Query params
         */
        getElectoralAreas(query = {}) {
            for (let param of Object.keys(this.params)) {
                if (this.params[param]) {
                    query[param] = this.params[param];
                }
            }

            this.load(query)
                .catch(errors => this.notify(this.buildErrors(errors), 'error'));
        },


        /**
         * Load all regions
         *
         */
        getConstituencies() {
            this.loadConstituencies()
                .catch(errors => this.notify(this.buildErrors(errors), 'error'));
        }
    },

    mounted() {
        this.getConstituencies();
        this.getElectoralAreas();
    }
}
</script>